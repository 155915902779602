<template>
  <v-app-bar
      app
      color="primary"
      dark
      dense
      hide-on-scroll
      style="z-index: 20"
    >

        <v-app-bar-nav-icon @click.stop="NavbarToggle">
            <v-icon>{{ toggleNavbarIcon }}</v-icon>
        </v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-divider vertical></v-divider>

      <v-menu
        offset-y
        origin="center top"
        :nudge-bottom="10"
        transition="scale-transition"
      >
         <template v-slot:activator="{on}">
            <v-btn
              large
              text
              v-on="on"
              class="ml-2"
            >
              <span class="mr-2"> {{ display_name }} </span>
              <v-icon medium>mdi-account</v-icon>

            </v-btn>
          </template>
        <v-list class="pa-0">
          <v-list-item
            v-if="isSuperAdmin"
            ripple="ripple"
            rel="noopener"
            @click="settings"
          >
            <v-list-item-action>
              <v-icon> mdi-cog-outline </v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Nastavenia</v-list-item-title>
            </v-list-item-content>

          </v-list-item>

          <v-divider> </v-divider>

          <v-list-item
            ripple="ripple"
            rel="noopener"
            @click="logout"
          >
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Odhlásiť sa</v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list>

      </v-menu>
    </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: "Topbar",

    data(){
        return{}
    },
    computed:{
        ...mapGetters(['navbarShow', 'display_name', 'isSuperAdmin']),

        toggleNavbarIcon(){
            return this.navbarShow ? 'mdi-format-indent-decrease' : 'mdi-format-indent-increase'
        }
    },
    methods:{
        ...mapActions(['NavbarToggle']),

        logout(){
          this.$store.dispatch('Logout');
          this.$router.push('/login');
        },

        settings(){
          this.$router.push('/settings');
        },
    }
}
</script>