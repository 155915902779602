import Vue from "vue"
import Router from "vue-router"
import store from "@/store"

import Layout from "@/views/layout/Layout.vue"

Vue.use(Router)

const routes = [
	{
		path: "/",
		component: Layout,
		meta: { requiresLogin: true },
		children: [
			{
				path: "/",
				alias: "/planovac",
				component: () => import("@/views/layout/Planovac"),
				name: "Dashboard",
				meta: {
					title: "route.planovac",
					noCache: true,
					affix: true,
				},
			},

			{
				path: "/settings",
				component: () => import("@/views/layout/Settings"),
				name: "Settings",
				meta: {
					title: "route.settings",
					noCache: true,
					affix: true,
					access: ["admin"],
				},
				// Route Guard
				beforeEnter: (to, from, next) => {
					if (to.meta.access.includes(store.getters.group)) {
						next()
					} else {
						next(from)
					}
				},
			},

			{
				path: "/reminder",
				component: () => import("@/views/layout/Reminder"),
				name: "Reminder",
				meta: {
					title: "route.reminder",
					noCache: true,
					affix: true,
					access: ["admin", "editor"],
				},
				// Route Guard
				beforeEnter: (to, from, next) => {
					if (to.meta.access.includes(store.getters.group)) {
						next()
					} else {
						next(from)
					}
				},
			},

			{
				path: "/graphs",
				component: () => import("@/views/layout/Graphs"),
				name: "Graphs",
				meta: {
					title: "route.graphs",
					noCache: true,
					affix: true,
					access: ["admin"],
				},
				// Route Guard
				beforeEnter: (to, from, next) => {
					if (to.meta.access.includes(store.getters.group)) {
						next()
					} else {
						next(from)
					}
				},
			},
			{
				path: "/tables",
				component: () => import("@/views/layout/Tables"),
				name: "Tables",
				meta: {
					title: "route.tables",
					noCache: true,
					affix: true,
					access: ["admin"],
				},
				// Route Guard
				beforeEnter: (to, from, next) => {
					if (to.meta.access.includes(store.getters.group)) {
						next()
					} else {
						next(from)
					}
				},
			},

			{
				path: "/user/create-account",
				component: () =>
					import(/* webpackPrefetch: true */ "@/views/layout/CreateAccount"),
				name: "Create Account",
				meta: {
					title: "route.create-account",
					noCache: true,
					affix: true,
					access: ["admin"],
				},
				// Route Guard
				beforeEnter: (to, from, next) => {
					if (to.meta.access.includes(store.getters.group)) {
						next()
					} else {
						next(from)
					}
				},
			},

			{
				path: "/user/list",
				component: () => import("@/views/layout/ListAccount"),
				name: "List Account",
				meta: {
					title: "route.list-account",
					noCache: true,
					affix: true,
					access: ["admin"],
				},
				// Route Guard
				beforeEnter: (to, from, next) => {
					if (to.meta.access.includes(store.getters.group)) {
						next()
					} else {
						next(from)
					}
				},
			},
		],
	},

	{
		path: "/login",
		alias: "/signin",
		name: "Login",
		component: () => import("@/views/auth/Login.vue"),
		hidden: true,
	},

	{
		path: "/setpassword",
		name: "Set Password",
		component: () => import("@/views/auth/SetPassword.vue"),
		hidden: true,
	},
]

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
})

export default router
