import Vuex from 'vuex'
import Vue from 'vue'

import createPersistedState from 'vuex-persistedstate'
//import VuexPersistence from 'vuex-persist'

import table from './modules/table'
import settings from './modules/settings'
import user from './modules/user'

// Load Vuex
Vue.use(Vuex);


// Create store
export default new Vuex.Store({
    modules:{
        settings,
        user,
        table
    },
    plugins: [
        createPersistedState({
            paths: ['user', 'table.technici'],
            storage: window.sessionStorage,
        })
    ]
})