<template>
	<v-navigation-drawer
		dark
		fixed
		app
		:value="navbarShow"
		width="250"
		@input="stateNavbarShow"
	>
		<v-app-bar dark short>
			<v-toolbar-title class="text-center">
				<span>STK</span>
			</v-toolbar-title>
		</v-app-bar>

		<v-list short class="layout-drawer">
			<v-list-item to="/" ripple="ripple">
				<v-list-item-icon class="layout-drawer__icon">
					<v-icon>mdi-table</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>
						Plánovač
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item to="/reminder" ripple="ripple" v-if="isAdmin">
				<v-list-item-icon class="layout-drawer__icon">
					<v-icon>mdi-email-alert</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>
						Pripomienky
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item to="/graphs" ripple="ripple" v-if="isSuperAdmin">
				<v-list-item-icon class="layout-drawer__icon">
					<v-icon>mdi-chart-bar-stacked</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>
						Grafy
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item to="/tables" ripple="ripple" v-if="isSuperAdmin">
				<v-list-item-icon class="layout-drawer__icon">
					<v-icon>mdi-file-table-outline</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>
						Tabuľky
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-group
				ripple="ripple"
				v-if="isSuperAdmin"
				prepend-icon="mdi-account-group"
				no-action
			>
				<template v-slot:activator>
					<v-list-item-content>
						<v-list-item-title>
							Účty
						</v-list-item-title>
					</v-list-item-content>
				</template>

				<v-list-item dense to="/user/list" ripple="ripple">
					<v-list-item-title>Zoznam</v-list-item-title>

					<v-list-item-icon>
						<v-icon>mdi-account-multiple</v-icon>
					</v-list-item-icon>
				</v-list-item>

				<v-list-item dense to="/user/create-account" ripple="ripple">
					<v-list-item-title>Pridať</v-list-item-title>

					<v-list-item-icon>
						<v-icon>mdi-account-plus</v-icon>
					</v-list-item-icon>
				</v-list-item>
			</v-list-group>
		</v-list>

		<template v-slot:append>
			<div align="center">
				<span style="color: grey">
					v1.1.1
				</span>
			</div>
		</template>
	</v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	name: "Sidebar",

	computed: {
		...mapGetters(["navbarShow", "isSuperAdmin", "isAdmin"]),
	},
	methods: {
		stateNavbarShow(state) {
			this.$store.dispatch("NavbarState", { state })
		},
	},
}
</script>

<style>
.layout-drawer {
	padding-bottom: 0px;
	padding-top: 0px;
}
.layout-drawer__icon {
	margin-bottom: 8px;
	margin-top: 8px;
}
</style>
