<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>

export default {
  name: 'App',
  title () {
    return `STK-TPD`
  },
};
</script>