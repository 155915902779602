<template>
  <div>
    <Topbar />

    <Sidebar />

    <v-main class="background">
      <transition name="fade">
        <keep-alive>
          <router-view />
        </keep-alive>
      </transition>
    </v-main>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";

import Topbar from "@/views/components/Topbar";
import Sidebar from "@/views/components/Sidebar";

import { users, getTableNames } from "@/data/api";

export default {
  name: "Layout",

  components: {
    Topbar,
    Sidebar
  },

  computed: { ...mapGetters(["socket"]) },

  data: () => ({
    //
  }),

  created() {
    // refresh technici everytime when created
    const getAllTechnici = () =>{

      axios.get(users(), {
          headers: {
            Authorization: "Token " + this.$store.getters.token,
            "Content-Type": "application/json"
          },
          params: {
            group: "basic"
          }
      })
      .then(response => {
        this.$store.dispatch("setTechnici", response.data.users);
      });
    }
  
    const getAllTables = () =>{
      axios.get(getTableNames(), {
        headers: {
          Authorization: "Token " + this.$store.getters.token,
          "Content-Type": "application/json"
        }
      }).then(response => {
        this.$store.dispatch("setLinky", response.data);
      })
    }

    getAllTechnici()
    getAllTables()

    // Connection opened
    this.$store.dispatch("startWebsocket", this.$store.getters.token);

    // eslint-disable-next-line no-unused-vars
    this.socket.onopen = function(event) {
      console.log("Openned");
    };

    // Listen for errors
    this.socket.onerror = function(event) {
      console.log(event)
      console.log("Error ", event.data);
    };

    // Close connections
    // eslint-disable-next-line no-unused-vars
    this.socket.onclose = function(event) {
      console.log("Closed");
    };
  },

  beforeDestroy() {
    this.socket.close();
  }
};
</script>

<style scoped>
.fade-leave-active {
  /*transition: opacity .3s;*/
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.background{
  min-height: 100vh;
  background-color: #fafafa;
}
</style>