import ReconnectingWebSocket from 'reconnecting-websocket'
import {webSocketUrl} from '@/data/api'

const state = {
    date: new Date(),
    date_string: null,

    linky : [],

    technici: [""],

    //newTable: false,
    show_only: null,
    show_self_records: false,
    filter: {},

    socket: null

};

const getters = {
    date: (state) => state.date,
    date_string: (state) => state.date_string,

    //new_table: (state) => state.newTable,
    linky: (state) => state.linky,
    technici: (state) => state.technici,

    show_only: (state) => state.show_only,
    show_self_records: (state) => state.show_self_records,
    filter: (state) => state.filter,

    socket: (state) => state.socket,
};

const actions = {
    async setLinky({ commit }, payload){
        await commit('SET_LINKY', payload)
    },

    async setLinkaName({ commit }, payload){
        console.log(payload)
        await commit('SET_LINKA_NAME', payload)
    },
    
    /*setNewTable({ commit }, payload){
        commit('SET_NEW_TABLE', payload)
    },*/

    async initialDate({ commit }){
        await commit('SET_INIT_DATE')
        await commit('SET_DATE_STRING')
    },

    async handleChangeDayBy({ commit }, changeBy){
        await commit('SET_DATE', state.date.getDate() + changeBy)
        await commit('SET_DATE_STRING')
    },

    async handleSetDate({commit}, payload){
        payload.setHours(0,0,0,0)

        // If isn't today
        if(payload.getTime() !== state.date.getTime()){
            let time = {date: payload.getDate(),
                month: payload.getMonth(),
                year: payload.getFullYear()}

            await commit("SET_FULL_DATE", time)
            await commit('SET_DATE_STRING')
        }
    },

    async changeShowOnly({commit}, payload){
        await commit('SET_SHOW', payload)
    },

    async changeSelfRecords({commit}){
        await commit('SET_SELF_RECORDS')
    },

    async changeFilter({commit}, payload){
        await commit('SET_FILTER', payload)
    },

    startWebsocket({commit}){
        const webSocket = webSocketUrl// + "?" + token
        commit('SET_SOCKET', webSocket)
    },

    async setTechnici({commit}, payload){
        commit('SET_TECHNICI', payload)
    },
};

const mutations = {
    SET_INIT_DATE: (state) => {
        
        state.date.setHours(0,0,0,0)
    },

    SET_LINKY: (state, payload) => {state.linky = payload},
    SET_LINKA_NAME: (state, payload) => {state.linky[payload.id].title = payload.title},
    //SET_NEW_TABLE: (state, payload) => {state.newTable = payload},
    SET_TECHNICI: (state, payload) => {state.technici = payload},
    
    SET_DATE_STRING: (state) => {
        const dd = state.date.getDate()
        const mm = state.date.getMonth() + 1
        const yyyy = state.date.getFullYear()

        state.date_string = dd + ". " + mm + ". " + yyyy
    },

    SET_DATE: (state, date) => { state.date.setDate(date) },
    SET_FULL_DATE: (state, payload) => { 
        state.date.setDate(payload.date);
        state.date.setMonth(payload.month);
        state.date.setFullYear(payload.year)
    },

    SET_SHOW: (state, payload) => {state.show_only = payload},
    SET_SELF_RECORDS: (state) => {state.show_self_records = !state.show_self_records},

    SET_FILTER: (state, payload) => {state.filter = payload},

    SET_SOCKET: (state, payload) => {
        const options = {
            WebSocket: WebSocket, // custom WebSocket constructor
            connectionTimeout: 1000,
            //maxRetries: 10,
        };
        state.socket = new ReconnectingWebSocket(payload, [], options)
    } //21837fc80ae8fbf7961a2db7976eaa4727fc7a6c
};

export default{
    state,
    getters,
    actions,
    mutations
}