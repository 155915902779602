const state = {
    navbar:{
        show: false
    }
};

const getters = {
    navbarShow: (state) => state.navbar.show
}

const mutations = {
    NAVBAR_TOGGLE: (state) => {
        state.navbar.show = !state.navbar.show
    },
    NAVBAR_STATE: (state, payload) => {
        state.navbar.show = payload.state;
    },
};

const actions = {
    NavbarToggle: async ({ commit }, payload) => {
        commit('NAVBAR_TOGGLE', payload)
    },
    NavbarState: async ({ commit }, payload) => {
        commit('NAVBAR_STATE', payload)
    },

}

export default{
    state,
    getters,
    mutations,
    actions
}