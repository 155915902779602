let domain = ""
let protocolHttp = "http://"
let protocolWs = "ws://"

if (location.protocol !== "http:") {
	protocolHttp = "https://"
	protocolWs = "wss://"
}

let webSocketUrl = ""

if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
	/*protocolHttp = "http://"
    protocolWs = "ws://"
    domain = "api.tpdcontrol.sk"
    domain = "142.93.163.138:8001"
    //webSocketUrl = protocolWs + domain + "/table/"*/

	domain = "127.0.0.1:8000"
	webSocketUrl = protocolWs + domain + "/table/"
} else {
	domain = "api.tpdcontrol.sk"
	//domain = "tpd-app.herokuapp.com"
	webSocketUrl = protocolWs + domain + ":9000/table/"
}

//domain = "192.168.100.5:8000"
//webSocketUrl = protocolWs + domain + "/table/"

const url = protocolHttp + domain

// http://127.0.0.1:8000/api/tables/tpd_stk-id0/get_table_data/
export function login() {
	return url + "/api/accounts/login/"
}

export function register() {
	return url + "/api/accounts/register/"
}

export function set_password() {
	return url + "/api/accounts/set-password/"
}

export function getTableNames() {
	return url + "/api/tables/"
}

export function setTableName(table) {
	return getTableNames() + table + "/"
}

export function tableSearch(table_id) {
	return url + "/api/tables/" + table_id + "/get_table_data/"
}

export function tableSearchWithDate(table_id, date) {
	return tableSearch(table_id) + "?date=" + date
}

export function poznamkyByValue(value) {
	return url + "/api/notes/" + value + "/"
}

export function poznamky() {
	return url + "/api/notes/"
}

export function users() {
	return url + "/api/users/"
}

export function csvRecords() {
	return url + "/api/tables/download_data/"
}

export function graphUser() {
	return url + "/api/graph/"
}
export function reviewTables() {
	return url + "/api/reviewTables/"
}

export function createCron() {
	return url + "/cron/requests/"
}

export { webSocketUrl, url }
