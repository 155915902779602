import axios from 'axios';

import {login} from '@/data/api' 

const state = {
    email: '',
    display_name: '',
    first_name: '',
    last_name: '',

    token: '',
    table_name: '',
    group: [],
};

const getters = {
    email: (state) => state.email,
    display_name: (state) => state.display_name,
    first_name: (state) => state.first_name,
    last_name: (state) => state.last_name,
    token: (state) => state.token,
    table_name_vuex: (state) => state.table_name,
    group: (state) => state.group,
    loggedIn(state){ return state.token !=  "" },

    isAdmin(state){ return ["admin", "editor"].includes(state.group)},
    isSuperAdmin(state){ return state.group == "admin"}

    //token: () => Cookies.get("token"),
    //loggedIn(state){ return !!state.email && !!Cookies.get('token') },
}

const mutations = {
    SET_USER_INFO: (state, payload) => {
        if (payload.logout) {
          state.token = '';
          state.email = '';
          state.display_name = '';
          state.first_name = '';
          state.last_name = '';
          state.table_name = '',
          state.group = '';

          window.localStorage.clear();
          window.localStorage.removeItem('vuex')
          window.sessionStorage.clear();
          window.sessionStorage.removeItem('vuex')

        } else {
          state.token = payload.token || state.token;
          state.email = payload.email || state.email;
          state.display_name = payload.display_name || state.display_name;
          state.first_name = payload.first_name || state.first_name;
          state.last_name = payload.last_name || state.last_name;
          state.table_name = payload.table_name ||state.table_name;
          state.group = payload.group || state.group;
        }
    },
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
}

const actions = {
    LoginByEmail: async ({ commit }, payload) => {
        await axios.post(login(),{
            username: payload.email.trim(),
            password: payload.password,
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(response =>{
            if(response.data.error){
                throw response.data.error
            }

            commit('SET_USER_INFO', response.data)
        }).catch(error => {
            throw error
        })
    },

    Logout: async ({ commit }) =>{
        await commit('SET_USER_INFO', {logout: true})
    },
}

export default{
    state,
    getters,
    mutations,
    actions
}